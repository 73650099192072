import { mobileOverrides } from '@tytapp/environments/mobile-overrides';
import { version } from '@tytapp/environments/version';

export type Environment = typeof environment;
export const environment = {
    name: 'production',
    version,
    production: true,
    angularProductionMode: true,
    enableServiceWorker: true,
    testing: false,
    showPanics: false,
    allowSearchEngines: true,
    apiOverride: undefined,
    showDevTools: false,
    showDevNotes: false,
    showEnvironmentHint: false,
    isNativeBuild: false,
    productName: 'TYT.com',
    urls: {
        root: 'https://tyt.com',
        webRoot: 'https://tyt.com',
        canonicalRoot: 'https://tyt.com',
        accounts: 'https://accounts.tyt.com',
        purchase: 'https://accounts.tyt.com/join',
        // purchase: `https://accounts.tyt.com/join/eec3a06`; // "site-nav" offer set for tracking
        // purchase: `https://join.tyt.com/join`; // for classic vs modern H2H test, October 6, 2020
        donate: 'https://secure.actblue.com/donate/tytnetwork',
        platform: 'https://platform.tyt.com',
        cable: 'https://platform.tyt.com/cable',
        api: 'https://platform.tyt.com/api/v1',
        admin: 'https://platform.tyt.com',
        forums: 'https://discuss.tyt.com',
        bantaServer: 'https://banta.tyt.com',
        cdn: 'https://cdn2.tytnetwork.com',
        releaseCdn: 'https://d3o2cozotd14f8.cloudfront.net',
        appStoreUrl: 'https://itunes.apple.com/us/app/tyt-plus-news-entertainment/id1319576607?mt=8',
        playStoreUrl: 'https://play.google.com/store/apps/details?id=com.tyt.battlecruiser&hl=en_US'
    },
    auth: {
        domain: '.tyt.com',
        cookie: 'tytauth',
        userCookie: 'tyt_user_uuid'
    },
    cookies: {
        storeInLocalStorage: false
    },
    logging: {
        verbose: true
    },
    serverLogging: {
        verbose: false
    },

    // KEYS
    stripeKey: 'pk_0zIUNvWdQMQTgAAOcX1k9p5BOUXTz',
    recaptchaV2SiteKey: '6LfORrIZAAAAADh6jtBF5aMhuEc3XfLjN4wBwepR',
    recaptchaV3SiteKey: '6LfsRbIZAAAAAMcH0NLIDxSqvJy3JNUs3XpWxQ4L',
    bugsnagKey: '100b690374ca57ef9bc9b16b1dd9c08a',
    googleCastAppId: 'F30612C0',
    vapidPublicKey: 'BJeYz77oT4pHu8oPc3PkVjqqyAEYhV9Jdjcx5YyeC_hiN5TleigLP51bQ71kob5cbCoatT3qRZwsGugvR98m0No',

    firebase: {
        apiKey: "AIzaSyBCFYjNvgT-OKu8Lxio-LrO9RyYm_AWaAg",
        authDomain: "tyt-plus-b5a16.firebaseapp.com",
        databaseURL: "https://tyt-plus-b5a16.firebaseio.com",
        projectId: "tyt-plus-b5a16",
        storageBucket: "tyt-plus-b5a16.appspot.com",
        messagingSenderId: "453075902535",
        appId: "1:453075902535:web:dc2edb959e797a55459162",
        measurementId: "G-Q3C2VEYM6K"
    }
};

mobileOverrides(environment);